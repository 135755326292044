.f-right {
  margin-left: auto;
}

.f-fill {
  height: 100%;
  margin: 0px;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

.f-grow {
  flex: 1 1 100%
}

.f-col,
.f-col-reverse,
.f-row,
.f-row-reverse {
  box-sizing: border-box !important;
  display: flex !important;
}

.wrap {
  flex-wrap: wrap !important;
}

.nowrap {
  flex-wrap: nowrap !important;
}

.f-col {
  flex-direction: column !important;
}

.f-col-reverse {
  flex-direction: column-reverse !important;
}

.f-row {
  flex-direction: row !important;
}

.f-row-reverse {
  flex-direction: row-reverse !important;
}

$alignments: (
  center: "center",
  end: "flex-end",
  space-between: "space-between",
  space-evenly: "space-evenly",
  start: "flex-start",
  stretch: "stretch"
);

@each $thisName, $thisAlign in $alignments {
  @each $thatName, $thatAlign in $alignments {
    .#{$thisName}-#{$thatName} {
      align-content: #{$thatAlign} !important;
      justify-content: #{$thisAlign} !important;
      align-items: #{$thatAlign} !important;
    }
  }
}

@for $i from 1 to 21 {
  .w-#{$i*5} {
    width: percentage($i * .05);
  }
}
