@import '~@angular/material/theming';
@import 'palettes';


@include mat-core();

$stil-primary: mat-palette($stil-red);
$stil-accent: mat-palette($mat-blue);
$stil-warn: mat-palette($mat-red);
$stil-theme: mat-dark-theme((
  color: (
    primary: $stil-primary,
    accent: $stil-accent,
    warn: $stil-warn,
  )
));

@include angular-material-theme($stil-theme);

$primary: mat-color($stil-primary);
$secondary: mat-color($stil-accent);
$warning: mat-color($stil-warn);

@import '~bootstrap/scss/bootstrap';

html, body {
  font: 400 14px Roboto, "Helvetica Neue", sans-serif;
  height: 100vh;
  margin: 0;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

*:focus {
  outline: none !important;
}

.mat-tooltip{
      color: rgb(255, 255, 255); // your custom properties here.
      opacity: 0.9;
      background-color: rgb(0, 0, 0);
      font-size: 12px !important;
  }


.infoIcon {
  color: rgb(206, 142, 142);
  font-size: 12px;
}

.formButton{
  float: right;
  min-width: 32px !important;
  padding: 0 !important;
}
