.f-right {
  margin-left: auto;
}

.f-fill {
  height: 100%;
  margin: 0px;
  min-height: 100%;
  min-width: 100%;
  width: 100%;
}

.f-grow {
  flex: 1 1 100%;
}

.f-col,
.f-col-reverse,
.f-row,
.f-row-reverse {
  box-sizing: border-box !important;
  display: flex !important;
}

.wrap {
  flex-wrap: wrap !important;
}

.nowrap {
  flex-wrap: nowrap !important;
}

.f-col {
  flex-direction: column !important;
}

.f-col-reverse {
  flex-direction: column-reverse !important;
}

.f-row {
  flex-direction: row !important;
}

.f-row-reverse {
  flex-direction: row-reverse !important;
}

.center-center {
  align-content: center !important;
  justify-content: center !important;
  align-items: center !important;
}

.center-end {
  align-content: flex-end !important;
  justify-content: center !important;
  align-items: flex-end !important;
}

.center-space-between {
  align-content: space-between !important;
  justify-content: center !important;
  align-items: space-between !important;
}

.center-space-evenly {
  align-content: space-evenly !important;
  justify-content: center !important;
  align-items: space-evenly !important;
}

.center-start {
  align-content: flex-start !important;
  justify-content: center !important;
  align-items: flex-start !important;
}

.center-stretch {
  align-content: stretch !important;
  justify-content: center !important;
  align-items: stretch !important;
}

.end-center {
  align-content: center !important;
  justify-content: flex-end !important;
  align-items: center !important;
}

.end-end {
  align-content: flex-end !important;
  justify-content: flex-end !important;
  align-items: flex-end !important;
}

.end-space-between {
  align-content: space-between !important;
  justify-content: flex-end !important;
  align-items: space-between !important;
}

.end-space-evenly {
  align-content: space-evenly !important;
  justify-content: flex-end !important;
  align-items: space-evenly !important;
}

.end-start {
  align-content: flex-start !important;
  justify-content: flex-end !important;
  align-items: flex-start !important;
}

.end-stretch {
  align-content: stretch !important;
  justify-content: flex-end !important;
  align-items: stretch !important;
}

.space-between-center {
  align-content: center !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.space-between-end {
  align-content: flex-end !important;
  justify-content: space-between !important;
  align-items: flex-end !important;
}

.space-between-space-between {
  align-content: space-between !important;
  justify-content: space-between !important;
  align-items: space-between !important;
}

.space-between-space-evenly {
  align-content: space-evenly !important;
  justify-content: space-between !important;
  align-items: space-evenly !important;
}

.space-between-start {
  align-content: flex-start !important;
  justify-content: space-between !important;
  align-items: flex-start !important;
}

.space-between-stretch {
  align-content: stretch !important;
  justify-content: space-between !important;
  align-items: stretch !important;
}

.space-evenly-center {
  align-content: center !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}

.space-evenly-end {
  align-content: flex-end !important;
  justify-content: space-evenly !important;
  align-items: flex-end !important;
}

.space-evenly-space-between {
  align-content: space-between !important;
  justify-content: space-evenly !important;
  align-items: space-between !important;
}

.space-evenly-space-evenly {
  align-content: space-evenly !important;
  justify-content: space-evenly !important;
  align-items: space-evenly !important;
}

.space-evenly-start {
  align-content: flex-start !important;
  justify-content: space-evenly !important;
  align-items: flex-start !important;
}

.space-evenly-stretch {
  align-content: stretch !important;
  justify-content: space-evenly !important;
  align-items: stretch !important;
}

.start-center {
  align-content: center !important;
  justify-content: flex-start !important;
  align-items: center !important;
}

.start-end {
  align-content: flex-end !important;
  justify-content: flex-start !important;
  align-items: flex-end !important;
}

.start-space-between {
  align-content: space-between !important;
  justify-content: flex-start !important;
  align-items: space-between !important;
}

.start-space-evenly {
  align-content: space-evenly !important;
  justify-content: flex-start !important;
  align-items: space-evenly !important;
}

.start-start {
  align-content: flex-start !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.start-stretch {
  align-content: stretch !important;
  justify-content: flex-start !important;
  align-items: stretch !important;
}

.stretch-center {
  align-content: center !important;
  justify-content: stretch !important;
  align-items: center !important;
}

.stretch-end {
  align-content: flex-end !important;
  justify-content: stretch !important;
  align-items: flex-end !important;
}

.stretch-space-between {
  align-content: space-between !important;
  justify-content: stretch !important;
  align-items: space-between !important;
}

.stretch-space-evenly {
  align-content: space-evenly !important;
  justify-content: stretch !important;
  align-items: space-evenly !important;
}

.stretch-start {
  align-content: flex-start !important;
  justify-content: stretch !important;
  align-items: flex-start !important;
}

.stretch-stretch {
  align-content: stretch !important;
  justify-content: stretch !important;
  align-items: stretch !important;
}

.w-5 {
  width: 5%;
}

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}

.w-30 {
  width: 30%;
}

.w-35 {
  width: 35%;
}

.w-40 {
  width: 40%;
}

.w-45 {
  width: 45%;
}

.w-50 {
  width: 50%;
}

.w-55 {
  width: 55%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-70 {
  width: 70%;
}

.w-75 {
  width: 75%;
}

.w-80 {
  width: 80%;
}

.w-85 {
  width: 85%;
}

.w-90 {
  width: 90%;
}

.w-95 {
  width: 95%;
}

.w-100 {
  width: 100%;
}

button:focus {
  outline: none !important;
}